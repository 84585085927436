import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import Identities from '../pages/identities'
import PaymentsLinks from '../pages/paymentsLinks'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/identities' />} />
        {/* Pages */}
        <Route path='identities' element={<Identities />} />
        <Route path='paymentsLinks' element={<PaymentsLinks />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
