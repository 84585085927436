import {usePostIdentitiesOnboardingForms, useGetIdentities} from '../modules/dashboard/requests'

export default function Identities() {
  const getIdentities = useGetIdentities({
    key: ['getIdentity'],
    enabled: true,
  })
  const postIdentitiesOnboardingForms = usePostIdentitiesOnboardingForms({
    onSuccess: (data) => {
      if (window && data?.onboarding_link?.link_url) {
        window.location = data?.onboarding_link?.link_url
      }
    },
  })

  return (
    <>
      {((getIdentities.error as any)?.response?.status === 404 || getIdentities.isSuccess || getIdentities.isLoading) && (
        <>
          <div className='card'>
            <div className='card-header'>
              {getIdentities?.isLoading ? (
                <h3 className='card-title'>Is Loading...</h3>
              ) : (
                <h3 className='card-title'>
                  {!getIdentities?.data ? 'Create Identity' : 'Identities List'}
                </h3>
              )}
            </div>
            {!getIdentities?.isLoading && (
              <div className='card-body'>
                {!getIdentities?.data ? (
                  <div>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary me-3'
                      onClick={() => {
                        postIdentitiesOnboardingForms?.mutate({})
                      }}
                      disabled={postIdentitiesOnboardingForms?.isLoading}
                    >
                      Create Identity
                    </button>
                  </div>
                ) : (
                  <>
                    <div className='card-p'>
                      Identity state{' '}
                      <span className='badge badge-secondary'>
                        {getIdentities?.data?.onboardingStateRaw ?? '...'}
                      </span>
                      {/* {getIdentities?.data?.onboardingState === 'APPROVED' && (
                        <>
                          <div>api-key : {`${getIdentities?.data?.apiKey}`}</div>
                          <div>
                            payment link :
                            <a
                              href={`/payment?merchantId=${getIdentities?.data?.merchantId}&applicationId=${getIdentities?.data?.applicationId}&transferId=&callbackURL=`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {`${window?.location?.origin}/payment?merchantId=${getIdentities?.data?.merchantId}&applicationId=${getIdentities?.data?.applicationId}&transferId=&callbackURL=`}
                            </a>
                          </div>
                          <p>
                            Create Transfer: /api/transfers (POST) Body : firstName, LastName,
                            email, amount, merchantId
                          </p>
                        </>
                      )} */}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className='separator my-10'></div>
        </>
      )}
      {/* <div className='card'>
        <div className='card-header'>
          <h3 className='card-title'>Payment Link Integration Tutorial</h3>
        </div>
        <div className='card-body'>
          <ol>
            <li>
              <p>
                Proceed to {window?.location?.origin}/identities. Register and wait for onboarding
                process result.
              </p>
            </li>
            <li>
              <p>
                After your registration is accepted and your identities status appears as Approved,
                you will be provided a payment link.
              </p>
              <b>IMPORTANT NOTE:</b>
              <br />
              <p>
                The payment link won't work until rest of the steps below are done and the payment
                link is integrated in your website.
              </p>
            </li>
            <li>
              <p>
                Send a post request to {process.env.REACT_APP_API_URL}/transfers, entering these
                parameters in request body: firstName, lastName, email, amount, merchantId.
              </p>
            </li>
            <li>
              <p>
                The merchantId is shown in the same page, but for other parameters, values must be
                added manually.
              </p>
            </li>
            <li>
              <p>
                In headers of the request, api-key must be added. api-key is also shown on the same
                page.
              </p>
            </li>
            <li>
              <p>The output of the request is the transferId.</p>
            </li>
            <li>
              <p>
                Finally you need to set the transferId in {window?.location?.origin}
                /payment?merchantId=MUw1ZczFjpfDQGXmfcZx9t6Q&applicationId=APnFnXvNik8u1pwbAPdpPMGf&transferId=&callbackURL=
                Which will redirect the user to the payment page.
              </p>
            </li>
            <li>
              <p>
                Then the callbackURL must be added, which is the URL of the page you want the user
                be redirected to after the payment is done.
              </p>
            </li>
            <li>
              <p>
                After a successful payment, you receive an ID {process.env.REACT_APP_API_URL}
                /transfers/:id which by sending a get request to the server, a list of transactions
                can be received.
              </p>
            </li>
          </ol>
        </div>
      </div> */}
    </>
  )
}
