import {useLayoutEffect} from 'react'
import {useSearchParams, useParams} from 'react-router-dom'
import {usePostIdentitiesToken} from '../modules/dashboard/requests'

export default function IdentityToken() {
  const [searchParams] = useSearchParams()
  const params = useParams()
  const postIdentitiesToken = usePostIdentitiesToken({})

  useLayoutEffect(() => {
    if (!!searchParams?.get('identityId')) {
      postIdentitiesToken
        .mutateAsync({
          identityId: searchParams?.get('identityId')!,
          token: params?.token!,
        })
        .finally(() => {
          setTimeout(() => {
            window.location.href = '/'
          }, 5000)
        })
    }
  }, [searchParams])

  return (
    <div className='card my-4 p-4 mx-auto mw-800px w-100'>
      <p className='card-p'>{postIdentitiesToken?.isLoading && 'Please wait ...'}</p>
    </div>
  )
}
