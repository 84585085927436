import {toast} from 'react-toastify'
import {toDateTime} from '../../_metronic/helpers/date'
import DataGrid from '../modules/DataGrid'
import {ColDef} from '../modules/DataGrid/interfaces'
import {IPaymentLink, IPostPaymentLinksParams} from '../modules/dashboard/interfaces'
import {useGetPaymentsLinks, usePostPaymentsLinks} from '../modules/dashboard/requests'
import {currencyFormatter} from '../../_metronic/helpers/currency'
import {Modal} from '../modules/Modal'
import {useEffect, useState} from 'react'
import {ZodType, z} from 'zod'
import {useFieldArray, useForm} from 'react-hook-form'
import {DevTool} from '@hookform/devtools'
import {zodResolver} from '@hookform/resolvers/zod'
import FormInput from '../modules/FormInput'
import FormSelect from '../modules/FormSelect'
import {AccordionItem, AccordionWrapper} from '../modules/Accordion'
import FormCheckBox from '../modules/FormCheckBox'

const colDefs: ColDef<IPaymentLink>[] = [
  {
    headerName: '#',
    valueGetter: 'node.rowIndex + 1',
    filter: false,
  },
  {
    field: 'created_at',
    headerName: 'Created On',
    valueGetter: (p) => toDateTime(p.data?.created_at as string),
  },
  {field: 'nickname', headerName: 'Payment Link Name'},
  {
    field: 'link_url',
    headerName: 'Link',
    filter: false,
    cellRenderer: (p) => {
      return (
        <button
          className='btn btn-primary px-2 py-1 fs-6'
          onClick={() => {
            navigator.clipboard.writeText(p.data.link_url)
            toast.info('Copied')
          }}
        >
          Copy Link
        </button>
      )
    },
  },
  {field: 'payment_frequency', headerName: 'Payment Link Type'},
  {
    field: 'amount_details.total_amount',
    headerName: 'Amount',
    valueGetter: (p) =>
      currencyFormatter(
        p.data?.amount_details.total_amount as number,
        p.data?.amount_details.currency
      ),
  },
  {
    field: 'state',
    headerName: 'Status',
    cellRenderer: (p) => {
      return (
        <span
          className={`badge ${
            {active: 'badge-success', expired: 'badge-danger'}[p.data.state.toLowerCase()] ??
            'badge-secondary'
          } `}
        >
          {p.data.state}
        </span>
      )
    },
  },
  {
    field: 'link_expires_at',
    headerName: 'Link Expiry',
    valueGetter: (p) => toDateTime(p.data?.link_expires_at as string),
  },
  {
    field: 'updated_at',
    headerName: 'Updated On',
    valueGetter: (p) => toDateTime(p.data?.updated_at as string),
  },
]

const PostPaymentLinksParamsSchema: ZodType<IPostPaymentLinksParams> = z.object({
  payment_frequency: z.enum(['ONE_TIME', 'RECURRING']),
  is_multiple_use: z.boolean(),
  nickname: z.string().min(1),
  allowed_payment_methods: z
    .array(z.enum(['PAYMENT_CARD', 'BANK_ACCOUNT']))
    .refine((data) => Boolean(data.length), {
      message: 'One or More',
      path: ['refine'],
    }),
  amount_details: z
    .object({
      amount_type: z.enum(['FIXED', 'VARIABLE']),
      total_amount: z.number().nonnegative().optional(),
      currency: z.string(),
      min_amount: z.number().nonnegative().optional(),
      max_amount: z.number().nonnegative().optional(),
    })
    .refine(
      (data) =>
        data.amount_type === 'FIXED' || Number(data?.min_amount) <= Number(data?.max_amount),
      {
        message: 'Max Amount Must be greater than or equal with the Min Amount',
        path: ['refine'],
      }
    ),
  additional_details: z.object({
    collect_name: z.boolean(),
    collect_email: z.boolean(),
    collect_phone: z.boolean(),
    collect_shipping_address: z.boolean(),
    collect_billing_address: z.boolean(),
    expiration_in_minutes: z.number().positive(),
    terms_of_service_url: z.string().url(),
    success_return_url: z.string().url().optional().or(z.literal('')),
    unsuccessful_return_url: z.string().url().optional().or(z.literal('')),
    expired_session_url: z.string().url().optional().or(z.literal('')),
  }),
  items: z.array(
    z.object({
      image_details: z.object({
        primary_image_url: z.string().url().optional().or(z.literal('')),
        alternative_image_urls: z.string().url().optional().or(z.literal('')),
      }),
      description: z.string().optional(),
      price_details: z.object({
        sale_amount: z.number().positive(),
        currency: z.string(),
        price_type: z.string().optional(),
        regular_amount: z.number().optional(),
      }),
      name: z.string().min(1),
      quantity: z.number().positive(),
    })
  ),
  branding: z.object({
    brand_color: z.string().optional(),
    accent_color: z.string().optional(),
    logo: z.string().url().optional().or(z.literal('')),
    icon: z.string().url().or(z.literal('')),
  }),
})

export default function PaymentsLinks() {
  const [isOpenPaymentLinkDialog, setIsOpenPaymentLinkDialog] = useState<boolean>(false)
  const getPaymentsLinks = useGetPaymentsLinks({
    key: ['PaymentsLinks'],
    enabled: true,
  })
  const postPaymentsLinks = usePostPaymentsLinks({onSuccess: () => getPaymentsLinks.refetch()})
  const itemDefaultValues = {
    name: '',
    quantity: 1,
    description: undefined,
    price_details: {
      currency: 'USD',
      sale_amount: undefined,
    },
    image_details: {
      primary_image_url: undefined,
    },
  }
  const {
    register,
    unregister,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: {errors},
  } = useForm<z.infer<typeof PostPaymentLinksParamsSchema>>({
    defaultValues: {
      payment_frequency: 'ONE_TIME',
      is_multiple_use: false,
      nickname: undefined,
      allowed_payment_methods: ['PAYMENT_CARD'],
      amount_details: {
        amount_type: undefined,
        total_amount: undefined,
        currency: 'USD',
        min_amount: undefined,
        max_amount: undefined,
      },
      additional_details: {
        collect_name: false,
        collect_email: true,
        collect_phone: false,
        collect_shipping_address: false,
        collect_billing_address: false,
        expiration_in_minutes: 7 * 24 * 60,
        terms_of_service_url: undefined,
        success_return_url: undefined,
        unsuccessful_return_url: undefined,
        expired_session_url: undefined,
      },
      branding: {
        accent_color: undefined,
        brand_color: undefined,
        icon: '',
        logo: undefined,
      },
      items: [JSON.parse(JSON.stringify(itemDefaultValues))],
    },
    resolver: zodResolver(PostPaymentLinksParamsSchema),
  })
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'items',
  })
  const [
    is_multiple_use,
    amount_details_amount_type,
    allowed_payment_methods,
    additional_details_expiration_in_minutes,
    items,
  ] = watch([
    'is_multiple_use',
    'amount_details.amount_type',
    'allowed_payment_methods',
    'additional_details.expiration_in_minutes',
    'items',
  ])

  function onSubmit(data: z.infer<typeof PostPaymentLinksParamsSchema>) {
    const payload = JSON.parse(JSON.stringify(data)) as IPostPaymentLinksParams
    let total_amount = 0

    for (let i = 0; i < payload.items.length; i++) {
      total_amount += payload.items[i].price_details.sale_amount * payload.items[i].quantity
    }

    if (payload.amount_details.amount_type === 'FIXED') {
      payload.amount_details.total_amount = total_amount
    }

    postPaymentsLinks.mutateAsync(payload).then(() => {
      setIsOpenPaymentLinkDialog(false)
      reset()
    })
  }

  useEffect(() => {
    if (amount_details_amount_type === 'FIXED') {
      unregister(['amount_details.min_amount', 'amount_details.max_amount'])
    }
  }, [register, unregister, amount_details_amount_type])

  return (
    <div className='h-75'>
      <div className='mb-3 d-flex flex-column'>
        <button
          className='btn btn-primary align-self-end'
          onClick={() => {
            setIsOpenPaymentLinkDialog(true)
          }}
          disabled={getPaymentsLinks.isLoading}
        >
          Create Payment Link
        </button>
      </div>
      <DataGrid<IPaymentLink>
        isLoading={getPaymentsLinks.isLoading}
        rowData={getPaymentsLinks?.data}
        colDefs={colDefs}
      />
      {isOpenPaymentLinkDialog && (
        <Modal
          title='Create Payment Link'
          isOpen={isOpenPaymentLinkDialog}
          onClose={() => {
            setIsOpenPaymentLinkDialog(false)
          }}
          onResolve={handleSubmit(onSubmit)}
          onReject={() => setIsOpenPaymentLinkDialog(false)}
          isLoading={postPaymentsLinks.isLoading}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='d-flex flex flex-column'
            style={{gap: '16px'}}
          >
            <DevTool placement='top-left' control={control} />
            <div className='d-flex flex-row' style={{gap: '16px'}}>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  checked={!is_multiple_use}
                  id='one_time_link'
                  onChange={(e) => {
                    setValue('is_multiple_use', !(e.target as any).checked)
                  }}
                />
                <label className='form-check-label' htmlFor='one_time_link'>
                  One-Time Link
                </label>
              </div>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  checked={is_multiple_use}
                  id='multi_use_link'
                  onChange={(e) => {
                    setValue('is_multiple_use', (e.target as any).checked)
                  }}
                />
                <label className='form-check-label' htmlFor='multi_use_link'>
                  Multi-Use Link
                </label>
              </div>
            </div>
            <FormInput
              label='Payment Link Name'
              name={'nickname'}
              error={errors.nickname}
              register={register}
              required
            />
            <FormSelect
              label='Amount Type'
              name={'amount_details.amount_type'}
              error={errors.amount_details?.amount_type}
              register={register}
              required
              options={[
                {
                  name: 'FIXED',
                  value: 'FIXED',
                },
                {
                  name: 'VARIABLE',
                  value: 'VARIABLE',
                },
              ]}
            />
            {amount_details_amount_type === 'VARIABLE' && (
              <div>
                <div className='d-flex flex-row' style={{gap: '16px'}}>
                  <FormInput
                    label='Min Amount'
                    name={'amount_details.min_amount'}
                    error={errors.amount_details?.min_amount}
                    register={register}
                    required
                    valueAsNumber
                  />
                  <FormInput
                    label='Max Amount'
                    name={'amount_details.max_amount'}
                    error={errors.amount_details?.max_amount}
                    register={register}
                    required
                    valueAsNumber
                  />
                </div>
                {(errors.amount_details as any)?.refine && (
                  <div className='mt-1 text-danger'>
                    {(errors.amount_details as any)?.refine?.message}
                  </div>
                )}
              </div>
            )}
            {amount_details_amount_type &&
              fields.map((field, index) => (
                <div key={field?.id}>
                  <div
                    className='bg-secondary p-4 rounded d-flex flex-column'
                    style={{gap: '16px'}}
                  >
                    <FormInput
                      label='Product or Service Name'
                      name={`items.${index}.name`}
                      error={errors.items?.[index]?.name}
                      register={register}
                      required
                    />
                    <FormInput
                      label='Description'
                      name={`items.${index}.description`}
                      error={errors.items?.[index]?.description}
                      register={register}
                    />
                    <div className='d-flex flex-row' style={{gap: '16px'}}>
                      <FormInput
                        label='Unit Price'
                        name={`items.${index}.price_details.sale_amount`}
                        error={errors.items?.[index]?.price_details?.sale_amount}
                        register={register}
                        required
                        valueAsNumber
                      />
                      <FormInput
                        label='Quantity'
                        name={`items.${index}.quantity`}
                        error={errors.items?.[index]?.quantity}
                        register={register}
                        valueAsNumber
                        required
                      />
                    </div>
                    <FormInput
                      label='Image URL'
                      name={`items.${index}.image_details.primary_image_url`}
                      error={errors.items?.[index]?.image_details?.primary_image_url}
                      register={register}
                    />
                    {index !== 0 && (
                      <button
                        className='btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger mt-4'
                        type='button'
                        onClick={() => remove(index)}
                      >
                        -Delete
                      </button>
                    )}
                  </div>
                </div>
              ))}
            <div className='bg-secondary p-4 rounded fs-6'>
              Total Amount :{' '}
              {!!items?.length &&
                currencyFormatter(
                  items.reduce(
                    (accumulator, currentValue) =>
                      accumulator +
                      currentValue.quantity *
                        ((currentValue.price_details?.sale_amount ?? 0) as number),
                    0
                  )
                )}
            </div>
            {amount_details_amount_type === 'FIXED' && (
              <button
                className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'
                type='button'
                onClick={() => append(JSON.parse(JSON.stringify(itemDefaultValues)))}
              >
                +ADD
              </button>
            )}
            <FormInput
              label='Terms of Service URL'
              name={'additional_details.terms_of_service_url'}
              error={errors.additional_details?.terms_of_service_url}
              register={register}
              required
            />
            <AccordionWrapper id='accordion_payment_links'>
              <AccordionItem
                id='accodion_item_allowed_payment_methods'
                idBody='accodion_item_allowed_payment_methods_body'
                label='Allowed Payment Methods'
                error={(errors.allowed_payment_methods as any)?.refine}
              >
                <div className='d-flex flex-column' style={{gap: '16px'}}>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={allowed_payment_methods.includes('PAYMENT_CARD')}
                      id='PAYMENT_CARD'
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (!allowed_payment_methods.includes('PAYMENT_CARD')) {
                            setValue('allowed_payment_methods', [
                              ...allowed_payment_methods,
                              'PAYMENT_CARD',
                            ])
                          }
                        } else {
                          setValue(
                            'allowed_payment_methods',
                            allowed_payment_methods.filter((item) => item !== 'PAYMENT_CARD')
                          )
                        }
                      }}
                    />
                    <label className='form-check-label' htmlFor='PAYMENT_CARD'>
                      Credit and Debit Cards
                    </label>
                  </div>
                  <div className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={allowed_payment_methods.includes('BANK_ACCOUNT')}
                      id='BANK_ACCOUNT'
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (!allowed_payment_methods.includes('BANK_ACCOUNT')) {
                            setValue('allowed_payment_methods', [
                              ...allowed_payment_methods,
                              'BANK_ACCOUNT',
                            ])
                          }
                        } else {
                          setValue(
                            'allowed_payment_methods',
                            allowed_payment_methods.filter((item) => item !== 'BANK_ACCOUNT')
                          )
                        }
                      }}
                    />
                    <label className='form-check-label' htmlFor='BANK_ACCOUNT'>
                      Bank Transfer
                    </label>
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                id='accodion_item_advanced_settings'
                idBody='accodion_item_advanced_settings_body'
                label='Advanced Settings'
              >
                <div className='d-flex flex-column' style={{gap: '16px'}}>
                  <div className='text-info'>Link Validity</div>
                  <div className='d-flex flex-row' style={{gap: '32px'}}>
                    <div className='d-flex flex-column' style={{gap: '16px'}}>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={additional_details_expiration_in_minutes === 24 * 60}
                          id='one_time_link'
                          onChange={(e) => {
                            setValue('additional_details.expiration_in_minutes', 24 * 60)
                          }}
                        />
                        <label className='form-check-label' htmlFor='one_time_link'>
                          24 hours
                        </label>
                      </div>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={additional_details_expiration_in_minutes === 48 * 60}
                          id='one_time_link'
                          onChange={(e) => {
                            setValue('additional_details.expiration_in_minutes', 48 * 60)
                          }}
                        />
                        <label className='form-check-label' htmlFor='one_time_link'>
                          48 hours
                        </label>
                      </div>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={additional_details_expiration_in_minutes === 72 * 60}
                          id='one_time_link'
                          onChange={(e) => {
                            setValue('additional_details.expiration_in_minutes', 72 * 60)
                          }}
                        />
                        <label className='form-check-label' htmlFor='one_time_link'>
                          72 hours
                        </label>
                      </div>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={additional_details_expiration_in_minutes === 7 * 24 * 60}
                          id='one_time_link'
                          onChange={(e) => {
                            setValue('additional_details.expiration_in_minutes', 7 * 24 * 60)
                          }}
                        />
                        <label className='form-check-label' htmlFor='one_time_link'>
                          1 week
                        </label>
                      </div>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={additional_details_expiration_in_minutes === 180 * 24 * 60}
                          id='one_time_link'
                          onChange={(e) => {
                            setValue('additional_details.expiration_in_minutes', 180 * 24 * 60)
                          }}
                        />
                        <label className='form-check-label' htmlFor='one_time_link'>
                          6 months
                        </label>
                      </div>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={additional_details_expiration_in_minutes === 365 * 24 * 60}
                          id='one_time_link'
                          onChange={(e) => {
                            setValue('additional_details.expiration_in_minutes', 365 * 24 * 60)
                          }}
                        />
                        <label className='form-check-label' htmlFor='one_time_link'>
                          3 years
                        </label>
                      </div>
                    </div>
                    <FormInput
                      label='Expiration In Minutes'
                      name={'additional_details.expiration_in_minutes'}
                      error={errors.additional_details?.expiration_in_minutes}
                      register={register}
                      required
                      valueAsNumber
                    />
                  </div>
                  <div className='text-info'>Buyer Details</div>
                  <FormCheckBox
                    label='Email'
                    name={'additional_details.collect_email'}
                    error={errors.additional_details?.collect_email}
                    register={register}
                  />
                  <FormCheckBox
                    label='Name'
                    name={'additional_details.collect_name'}
                    error={errors.additional_details?.collect_name}
                    register={register}
                  />
                  <FormCheckBox
                    label='Phone Number'
                    name={'additional_details.collect_phone'}
                    error={errors.additional_details?.collect_phone}
                    register={register}
                  />
                  <FormCheckBox
                    label='Shipping Address'
                    name={'additional_details.collect_shipping_address'}
                    error={errors.additional_details?.collect_shipping_address}
                    register={register}
                  />
                  <FormCheckBox
                    label='Billing Address'
                    name={'additional_details.collect_billing_address'}
                    error={errors.additional_details?.collect_billing_address}
                    register={register}
                  />
                  <FormInput
                    label='Custom success return URL'
                    name={'additional_details.success_return_url'}
                    error={errors.additional_details?.success_return_url}
                    register={register}
                  />
                  <FormInput
                    label='Custom failure return URL'
                    name={'additional_details.unsuccessful_return_url'}
                    error={errors.additional_details?.unsuccessful_return_url}
                    register={register}
                  />
                  <FormInput
                    label='Custom success expired URL'
                    name={'additional_details.expired_session_url'}
                    error={errors.additional_details?.expired_session_url}
                    register={register}
                  />
                </div>
              </AccordionItem>
              <AccordionItem
                id='accodion_item_custom_branding'
                idBody='accodion_item_custom_branding_body'
                label='Custom Branding'
              >
                <div className='d-flex flex-column' style={{gap: '16px'}}>
                  <FormInput
                    label='Logo URL'
                    name={'branding.logo'}
                    error={errors.branding?.logo}
                    register={register}
                  />
                  <div className='d-flex flex-row align-items-center' style={{gap: '16px'}}>
                    <FormInput
                      label='Header Color'
                      name={'branding.brand_color'}
                      error={errors.branding?.brand_color}
                      register={register}
                    />
                    <input
                      type='color'
                      className='h-20 mt-10'
                      onChange={(e) => {
                        setValue('branding.brand_color', e.target.value)
                      }}
                    />
                  </div>
                  <div className='d-flex flex-row align-items-center' style={{gap: '16px'}}>
                    <FormInput
                      label='Button Background Color'
                      name={'branding.accent_color'}
                      error={errors.branding?.accent_color}
                      register={register}
                    />
                    <input
                      type='color'
                      className='h-20 mt-10'
                      onChange={(e) => {
                        setValue('branding.accent_color', e.target.value)
                      }}
                    />
                  </div>
                </div>
              </AccordionItem>
            </AccordionWrapper>
          </form>
        </Modal>
      )}
    </div>
  )
}
