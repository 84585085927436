/* eslint-disable react/jsx-no-target-blank */

import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to='/identities' icon='element-3' title='Identities' />
      <AsideMenuItem to='/paymentsLinks' icon='element-3' title='Payments Links' />
    </>
  )
}
