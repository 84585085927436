import {FieldValues} from 'react-hook-form'
import {FormInputProps} from './interfaces'

export default function FormCheckBox<TFormData extends FieldValues>({
  type = 'checkbox',
  placeholder,
  required = false,
  label,
  name,
  register,
  error,
  fullWidth = true,
  id,
  ...props
}: FormInputProps<'input', TFormData>) {
  return (
    <>
      <div className='form-check form-check-custom form-check-solid'>
        <input
          id={name}
          className=' form-check-input'
          {...props}
          type={type}
          placeholder={placeholder}
          {...register(name)}
        />
        <label htmlFor={name} className={`${required && 'required'} form-check-label`}>
          {label}
        </label>
        {error && <div className='mt-1 text-danger'>{error.message}</div>}
      </div>
    </>
  )
}
