import {FieldValues} from 'react-hook-form'
import {FormInputProps} from './interfaces'

export default function FormInput<TFormData extends FieldValues>({
  placeholder,
  required = false,
  label,
  name,
  register,
  error,
  fullWidth = true,
  valueAsNumber,
  id,
  options,
  ...props
}: FormInputProps<'select', TFormData>) {
  return (
    <>
      <div>
        <label htmlFor={name} className={`${required && 'required'} form-label`}>
          {label}
        </label>
        <select
          className='form-select form-select-solid'
          id={name}
          {...props}
          placeholder={placeholder}
          {...register(name, {valueAsNumber})}
        >
          <option value=''>--Please choose an option--</option>
          {options?.map((option) => (
            <option key={option.name} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        {error && <div className='mt-1 text-danger'>Required</div>}
      </div>
    </>
  )
}
