import {KTSVG} from '../../../_metronic/helpers'
import {IDecisionModal} from './interfaces'

export function Modal({
  title,
  isOpen,
  isLoading = false,
  onClose,
  onResolve,
  onReject = onClose,
  children,
}: IDecisionModal) {
  return (
    <>
      <div style={{display: isOpen ? 'block' : 'none'}} className={`modal bg-black bg-opacity-25`} tabIndex={-1}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{title}</h5>
              <button
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={onClose}
                disabled={isLoading}
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
            </div>
            <div className='modal-body'>
              <p>{children}</p>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                onClick={onReject}
                disabled={isLoading}
              >
                Close
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={onResolve}
                disabled={isLoading}
              >
                Save changes {isLoading && '...'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
