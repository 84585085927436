import {TAccordionItemProps, TAccordionWrapperProps} from './interfaces'

export function AccordionWrapper({children, id}: TAccordionWrapperProps): JSX.Element {
  return (
    <>
      <div className='accordion' id={id}>
        {children}
      </div>
    </>
  )
}

export function AccordionItem({
  children,
  id,
  idBody,
  label,
  error = false,
}: TAccordionItemProps): JSX.Element {
  return (
    <div className='accordion-item'>
      <h2 className='accordion-header' id={id}>
        <button
          className={`accordion-button fs-4 fw-bold collapsed fs-6 ${error && 'text-danger'}`}
          type='button'
          data-bs-toggle='collapse'
          data-bs-target={`#${idBody}`}
          aria-expanded='false'
          aria-controls={idBody}
        >
          {label}
        </button>
      </h2>
      <div id={idBody} className='accordion-collapse collapse' aria-labelledby={id}>
        <div className='accordion-body'>{children}</div>
      </div>
    </div>
  )
}
