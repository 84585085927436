import axios from 'axios'
import {useMutation, useQuery} from 'react-query'
import {toast} from 'react-toastify'
import {getRefreshToken} from '@descope/react-sdk'
import {IIdentity, IPaymentLink, IPostPaymentLinksParams} from './interfaces'

export const dashboardAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

dashboardAPI.interceptors.request.use((config) => {
  try {
    const sessionToken = getRefreshToken()

    if (sessionToken) {
      config.headers.Authorization = `Bearer ${sessionToken}`
    }

    return config
  } catch (error) {
    return config
  }
})

dashboardAPI.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const errorMessage =
      error?.response?.data?.message ??
      error?.response?.data?.Message ??
      error?.response?.data?.title ??
      error?.response?.data?.Title ??
      error?.message ??
      error?.Message ??
      JSON?.stringify(error?.response?.data) ??
      'Something is wrong!'
    if (error?.response?.status === 401) {
      if (typeof window !== 'undefined') {
        window?.localStorage?.clear()
        window?.sessionStorage?.clear()
        window?.location?.replace('/')
      }
    } else if (error?.response?.status === 404) {
      // toast.error('No data!')
    } else {
      toast.error(errorMessage)
    }

    return Promise.reject(error)
  }
)

export async function getIdentities() {
  const {data} = await dashboardAPI.get<Partial<IIdentity>>('/identities')

  return data
}

export const useGetIdentities = ({key, enabled}) => {
  return useQuery(key, () => getIdentities(), {
    enabled,
  })
}

export async function postIdentitiesOnboardingForms(payload) {
  const {data} = await dashboardAPI.post('/identities/onboardingForms', payload)

  return data
}

export const usePostIdentitiesOnboardingForms = ({onSuccess}: {onSuccess: Function}) =>
  useMutation({
    mutationFn: (payload: object) => postIdentitiesOnboardingForms(payload),
    onSuccess: (data) => {
      toast?.info('Wait for identity form')
      onSuccess(data)
    },
  })

export async function postIdentitiesToken({token, identityId}) {
  const {data} = await dashboardAPI.post(`/identities/token/${token}?identityId=${identityId}`)

  return data
}

export const usePostIdentitiesToken = ({onSuccess = () => {}}: {onSuccess?: Function}) =>
  useMutation({
    mutationFn: (payload: {token: string; identityId: string}) => postIdentitiesToken(payload),
    onSuccess: (data) => {
      toast?.info(`Your request has been successfully registered`, {type: 'success'})
      onSuccess(data)
    },
  })

export async function postPayments(payload) {
  const {data} = await dashboardAPI.post('/payments', payload)

  return data
}

export const usePostPayments = ({onSuccess = () => {}}: {onSuccess?: Function}) =>
  useMutation({
    mutationFn: (payload: object) => postPayments(payload),
    onSuccess: (data) => {
      toast?.info(`Your payment state: ${data?.transferState}, REDIRECTING ...`, {type: 'success'})
      onSuccess(data)
    },
  })

export async function getPaymentsLinks() {
  const {data} = await dashboardAPI.get<IPaymentLink[]>('/paymentLinks')

  return data
}

export const useGetPaymentsLinks = ({key, enabled}) => {
  return useQuery(key, () => getPaymentsLinks(), {
    enabled,
  })
}

export async function postPaymentsLinks(payload: IPostPaymentLinksParams) {
  const {data} = await dashboardAPI.post('/paymentLinks', payload)

  return data
}

export const usePostPaymentsLinks = ({onSuccess = () => {}}: {onSuccess?: Function}) =>
  useMutation({
    mutationFn: (payload: IPostPaymentLinksParams) => postPaymentsLinks(payload),
    onSuccess: (data) => {
      toast?.info(`Payment Link is Created!`, {type: 'success'})
      onSuccess(data)
    },
  })
