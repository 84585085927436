import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import React, {useEffect, useState} from 'react'
import {AgGridReact} from 'ag-grid-react'
import {ColDef, GridApi} from 'ag-grid-community'
import {IDataGrid} from './interfaces'

export default function DataGrid<TData>({rowData, colDefs, isLoading = false}: IDataGrid<TData>) {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [colDefsState, setColDefsState] = useState<ColDef<TData>[]>(colDefs)
  const onGridReady = (params) => {
    setGridApi(params.api)
  }

  useEffect(() => {
    if (gridApi) {
      if (isLoading) {
        gridApi.showLoadingOverlay()
      } else {
        gridApi.hideOverlay()
      }
    }
  }, [gridApi, isLoading])

  return (
    <div className={'ag-theme-quartz'} style={{width: '100%', height: '100%'}}>
      <AgGridReact
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: 'agTextColumnFilter',
          menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab'],
          enablePivot: true,
          minWidth: 100,
        }}
        autoSizeStrategy={{
          type: 'fitCellContents',
        }}
        rowData={rowData}
        columnDefs={colDefsState}
        onGridReady={onGridReady}
      />
    </div>
  )
}
