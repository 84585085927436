import {useLayoutEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {useSearchParams} from 'react-router-dom'
import {usePostPayments} from '../modules/dashboard/requests'
import {toast} from 'react-toastify'

export default function Payment() {
  const [searchParams] = useSearchParams()
  const [sessionId, setSessionID] = useState(null)
  const merchantId = searchParams.get('merchantId')
  const transferId = searchParams.get('transferId')
  const applicationId = searchParams.get('applicationId')
  const callbackURL = searchParams.get('callbackURL') ?? '/'
  const [env] = useState(process.env.REACT_APP_FINIX_ENVIRONMENT ?? '')
  const postPayments = usePostPayments({
    onSuccess: (data) =>
      setTimeout(() => {
        if (window && window?.location) {
          window.location.href = `${callbackURL}?id=${data?.id}`
        }
      }, 5000),
  })

  useLayoutEffect(() => {
    setTimeout(() => {
      if (window?.Finix && !sessionId) {
        window.Finix.Auth(env, merchantId, (sessionKey) => {
          if (sessionKey) {
            setSessionID(sessionKey)
          }
        })
      }
    }, 2000)
  }, [])

  useLayoutEffect(() => {
    if (window?.Finix && sessionId && merchantId && applicationId && transferId) {
      const options = {
        showAddress: true, // show address fields in the form (default is false)
        showLabels: true, //show labels in the form (default is true)
        labels: {
          // set custom labels for each field
          name: 'Cardholder Name', // Supported Fields: "name", "number", "expiration_date", "security_code", "address_line1", "address_line2", "address_city", "address_region", "address_country", "address_postal_code"
        },
        showPlaceholders: true, // turn on or off placeholder text in the fields (default is true)
        placeholders: {
          // set custom placeholders for each field, you can specify them here
          // Supported Fields: "name", "number", "expiration_date", "security_code", "address_line1", "address_line2", "address_city", "address_region", "address_country", "address_postal_code"
          name: 'Cardholder Name',
        },
        hideFields: [
          'address_line1',
          'address_line2',
          // set custom placeholders for each field, you can specify them here
        ], // Fields avaiblae to hide: "name", "security_code", "address_line1", "address_line2", "address_city", "address_region", "address_country", "address_postal_code", "address_country"
        requiredFields: [
          // require any specific fields that are not required by default, you can specify them here
          'name',
          'address_city',
          'address_region',
          'address_country',
          'address_postal_code',
        ], // Supported Fields: "name", "address_line1", "address_line2", "address_city", "address_region", "address_country", "address_postal_code"
        hideErrorMessages: false, // if you want to require a field, but not hide input error messages (default is false)
        errorMessages: {
          // set custom error messages for each field if you are showing error messages
          name: 'Please enter a valid name', // Supported Fields: "name", "number", "expiration_date", "security_code", "address_line1", "address_line2", "address_city", "address_region", "address_country", "address_postal_code"
          address_city: 'Please enter a valid city',
        },
        onSubmit: () => {
          toast?.info(`Please wait ...`)
          form.submit(env, applicationId, function (err, res) {
            // call the form submit function and supply the environment and application ID to submit under
            const tokenData = res.data || {}

            if (tokenData?.id) {
              if (tokenData?.id && sessionId && merchantId && transferId) {
                postPayments
                  .mutateAsync({
                    transferId,
                    tokenId: tokenData?.id,
                    sessionId,
                    merchantId,
                  })
                  .catch((error) => {
                    toast.error(error?.response?.data?.message ?? 'Something is wrong!')
                  })
              }
            }
          })
        },
      }
      const form = window.Finix.TokenForm('form-elements', options)
    }
  }, [sessionId])

  return (
    <div className='card my-4 p-4 mx-auto mw-800px w-100'>
      <div className='card-header'>
        <h3 className='card-title'>Payment Form</h3>
      </div>
      {sessionId ? (
        <>
          <div className='card-body'>
            <h4 className='card-title mb-4'>Create Payment</h4>
            <div id='form-elements'></div>
          </div>
        </>
      ) : (
        <p className='my-4'>Loading ...</p>
      )}
      <Helmet>
        <script type='text/javascript' src='https://js.finix.com/v/1/finix.js' async></script>
      </Helmet>
    </div>
  )
}
