import {FieldValues} from 'react-hook-form'
import {FormInputProps} from './interfaces'

export default function FormInput<TFormData extends FieldValues>({
  type,
  placeholder,
  required = false,
  label,
  name,
  register,
  error,
  fullWidth = true,
  valueAsNumber,
  id,
  ...props
}: FormInputProps<'input', TFormData>) {
  return (
    <>
      <div>
        <label htmlFor={name} className={`${required && 'required'} form-label`}>
          {label}
        </label>
        <input
          id={name}
          className='form-control form-control-solid'
          {...props}
          type={type}
          placeholder={placeholder}
          {...register(name, {valueAsNumber})}
        />
        {error && <div className='mt-1 text-danger'>{error.message}</div>}
      </div>
    </>
  )
}
